<template>
  <el-card>
    <el-form
      ref="addFormRef"
      :model="addForm"
      :rules="addFormRules"
      label-width="100px"
    >
      <el-form-item label="配镜时间：" prop="pjsj">
        <el-date-picker
          format="yyyy-M-d"
          v-model="addForm.pjsj"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="姓名：" prop="name">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.name"
          placeholder="请输入顾客姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="性别：" prop="gender">
        <el-radio v-model="addForm.gender" label="男">男</el-radio>
        <el-radio v-model="addForm.gender" label="女">女</el-radio>
      </el-form-item>

      <el-form-item label="年龄：" prop="age">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.age"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否为会员" prop="ishy">
        <el-radio v-model="addForm.ishy" label="是">是</el-radio>
        <el-radio v-model="addForm.ishy" label="否">否</el-radio>
      </el-form-item>

            <el-form-item label="卡内积分：" prop="knjf">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.knjf"
        ></el-input>
      </el-form-item>

      
      <el-form-item label="卡内余额：" prop="knye">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.knye"
        ></el-input>
      </el-form-item>


      <el-form-item label="运用类型：" prop="applicationType">
        <el-radio v-model="addForm.applicationType" label="近用">近用</el-radio>
        <el-radio v-model="addForm.applicationType" label="远用">远用</el-radio>
      </el-form-item>

      <el-form-item label="配镜处方：" prop="pjcf">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="rowStyle"
        >
          <el-table-column
            prop="pjcf"
            label="配镜处方"
            header-align="center"
          ></el-table-column>
          <el-table-column prop="qj" label="球镜(SPH)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="qj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.qj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="zj" label="柱镜(CYL)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="zw" label="轴位(AX)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zw">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zw"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="tj" label="瞳距(mm)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="tj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.tj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="tg" label="瞳高(mm)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="tg">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.tg"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="xjg" label="下加光(Add)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="xjg">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.xjg"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="jzsl" label="矫正视力" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="jzsl">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.jzsl"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>

        <el-table
          :data="tableDatas"
          border
          style="width: 100%"
          :cell-style="rowStyle"
        >
          <el-table-column
            prop="pjcf"
            label="配镜处方"
            header-align="center"
          ></el-table-column>

          <el-table-column prop="zqj" label="球镜(SPH)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zqj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zqj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="zzj" label="柱镜(CYL)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zzj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zzj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="zzw" label="轴位(AX)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zzw">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zzw"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="lefttj" label="瞳距(mm)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="lefttj">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.lefttj"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ztg" label="瞳高(mm)" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="ztg">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.ztg"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="zxjg"
            label="下加光(Add)"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-form-item prop="zxjg">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zxjg"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="zjzsl" label="矫正视力" header-align="center">
            <template slot-scope="scope">
              <el-form-item prop="zjzsl">
                <el-input
                  style="width: 100px"
                  size="mini"
                  v-model="addForm.zjzsl"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="总瞳距：" prop="ztj">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.ztj"
          placeholder="请输入顾客总瞳距"
        ></el-input>
      </el-form-item>

      <el-form-item label="验光师：" prop="ygs">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.ygs"
          placeholder="请输入验光师"
        ></el-input>
      </el-form-item>

      <el-form-item label="镜架名称：" prop="jjmc">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.jjmc"
          placeholder="请输入镜架名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="镜片名称：" prop="jpmc">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.jpmc"
          placeholder="请输入镜片名称"
        ></el-input>
      </el-form-item>

    

      <el-form-item label="顾客卡号：" prop="gkkh">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.gkkh"
          placeholder="请输入顾客卡号"
        ></el-input>
      </el-form-item>

      <el-form-item label="地址：" prop="gkdjs">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.gkdjs"
          placeholder="请输入顾客地址"
        ></el-input>
      </el-form-item>

      <el-row>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="原镜片价格：" prop="yjpjg">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="addForm.yjpjg"
                placeholder="请原镜片价格"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="单位：" prop="yjpdw">
              <el-input
                style="width: 50px"
                size="mini"
                v-model="addForm.yjpdw"
              ></el-input
              >付/对
            </el-form-item>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="优惠折扣：" prop="jpdz">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="addForm.jpdz"
                placeholder="请输入优惠价格"
              ></el-input>
            </el-form-item></div
        ></el-col>

        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="优惠后价格：" prop="yjyhjg">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="jpsum"
                placeholder="请输入优惠价格"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>

      <el-row>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="原镜架价格：" prop="yjjjg">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="addForm.yjjjg"
                placeholder="请原镜片价格"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="单位：" prop="jjdw">
              <el-input
                style="width: 50px"
                size="mini"
                v-model="addForm.jjdw"
              ></el-input
              >付/对
            </el-form-item>
          </div></el-col
        >

        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="优惠折扣：" prop="jjdz">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="addForm.jjdz"
                placeholder="请输入优惠价格"
              ></el-input>
            </el-form-item></div
        ></el-col>

        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <el-form-item label="优惠后价格：" prop="jjjg">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="inputFunc"
                @input="inputFunc()"
                placeholder="请输入优惠后价格"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>

      <el-row>
        <el-col :span="6"
          ><div class="grid-content bg-purple-dark">
            <el-form-item label="应收金额：" prop="ysje">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="sum"
                @input="sum()"
                placeholder="请输入优惠后价格"
              ></el-input>
            </el-form-item></div
        ></el-col>

        <el-col :span="6"
          ><div class="grid-content bg-purple-dark">
            <el-form-item label="实收金额：" prop="ssje">
              <el-input
                style="width: 180px"
                size="mini"
                v-model="addForm.ssje"
                placeholder="请输入优惠后价格"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>

      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="addForm.remark"
          placeholder="请输入地址"
        ></el-input>
      </el-form-item>
      <!-- 所在省 -->
      <!-- <el-form-item label="所在省" prop="province">
        <el-select v-model="addForm.province"
                   clearable
                   @change="queryCity">
          <el-option v-for="area in area.provinceList"
                     :key="area.name"
                     :label="area.name"
                     :value="area.id"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- 所在市 -->
      <!-- <el-form-item label="所在市" prop="city">
        <el-select v-model="addForm.city"
                   clearable
                   @change="queryArea">
          <el-option v-for="area in area.cityList"
                     :key="area.name"
                     :label="area.name"
                     :value="area.id"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- 所在区 -->
      <!-- <el-form-item label="所在区" prop="area">
        <el-select v-model="addForm.area"
                   clearable>
          <el-option v-for="area in area.areaList"
                     :key="area.name"
                     :label="area.name"
                     :value="area.id"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- 租赁方式 -->
      <!-- <el-form-item label="方式" prop="rentMode">
        <el-select v-model="addForm.rentMode" clearable>
          <el-option
            v-for="dict in dict.rentModeList"
            :key="dict.name"
            :label="dict.name"
            :value="dict.value"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <!-- 户型 -->
      <!-- <el-form-item label="户型" prop="houseType">
        <el-radio-group v-model="addForm.houseType" size="medium"> -->
      <!-- label是值，页面显示的内容要写在el-radio-button标签中间 -->
      <!-- <el-radio-button
            v-for="dict in dict.houseTypeList"
            :key="dict.name"
            :label="dict.value"
            >{{ dict.name }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item> -->

      <!-- 朝向 -->
      <!-- <el-form-item label="朝向" prop="direction">
        <el-radio-group v-model="addForm.direction" size="medium"> -->
      <!-- label是值，页面显示的内容要写在el-radio-button标签中间 -->
      <!-- <el-radio-button
            v-for="dict in dict.directionList"
            :key="dict.name"
            :label="dict.value"
            >{{ dict.name }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item> -->
      <!-- 租金：文本框 -->
      <!-- <el-form-item label="租金" prop="rental">
        <el-input v-model="addForm.rental" placeholder="请输入租金"></el-input>
      </el-form-item> -->
      <!-- 地址：文本域 -->
      <!-- <el-form-item label="地址" prop="address">
        <el-input
          type="textarea"
          v-model="addForm.address"
          placeholder="请输入地址"
        ></el-input>
      </el-form-item> -->
      <!-- 图片 -->
      <!-- <el-form-item label="图片" prop="pic">
        <el-upload :action="path + '/house/upload'"
             :headers="headers"
            :file-list="fileList"
            :on-success="onUploadSuccess"
            :limit="1"
            :on-exceed="onUploadExceed"
            :before-upload="beforeUpload"
            :on-remove="onPicRemove"
            list-type="picture">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
        </el-upload>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submitAddForm">立即添加</el-button>
        <el-button @click="resetAddForm">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  name: "Add",
  data() {
    var checkRental = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("租金不能为空"));
      }

      if (!Number(value)) {
        callback(new Error("请输入大于0的数字值"));
      } else {
        let reg = /^\d+.?\d{0,2}$/;
        if (!reg.test(value)) {
          callback(new Error("只能是整数或最多两位小数"));
        } else if (value <= 100 || value > 100000) {
          callback(new Error("租金只能在100-10万之后"));
        } else {
          callback();
        }
      }
    };
    return {
      tableData: [
        {
          pjcf: "右（R）",
        },
      ],
      tableDatas: [
        {
          pjcf: "左（L）",
        },
      ],

      headers: {
        token: sessionStorage.getItem("token"),
      },
      fileList: [],
      addForm: {
        ishy: "",
        knye: "",
        knjf: "",
        pjsj: "",
        name: "",
        gender: "",
        age: "",
        occupation: "",
        rentMode: "",
        pjcf: "",
        qj: "",
        zj: "",
        zw: "",
        tj: "",
        tg: "",
        xjg: "",
        jzsl: "",
        ztj: "",
        ygs: "",
        jjmc: "",
        jpmc: "",
        pjlx: "",
        pjcl: "",
        pjys: "",
        pjzsl: "",
        pjmc: "",
        gkkh: "",
        gkdjs: "",
        gksr: "",
        yjpjg: "",
        yjpdw: "",
        jpdz: "",
        yjyhjg: "",
        yjjjg: "",
        jjdw: "",
        jjdz: "",
        jjjg: "",
        ysje: "",
        ssje: "",
        remark: "",
        applicationType: "",
        zqj: "",
        zzj: "",
        zzw: "",
        lefttj: "",
        ztg: "",
        zxjg: "",
        zjzsl: "",
      },
      area: {
        /* 存储省市区列表数据 */ provinceList: [],
        cityList: [],
        areaList: [],
      },
      dict: {
        /* 保存字典数据 */
        PJLXLIST: [],
        XHLIST: [],
        CLLIST: [],
        ZSLLIST: [],
        YSLIST: [],
        MCLIST: [],
      },
      addFormRules: {
        province: [
          { required: true, message: "请选择所在省", trigger: "change" },
        ],
        city: [{ required: true, message: "请选择所在市", trigger: "change" }],
        area: [{ required: true, message: "请选择所在区", trigger: "change" }],
        // rentMode: [
        //   { required: true, message: "请选择方式", trigger: "change" },
        // ],
        houseType: [
          { required: true, message: "请选择户型", trigger: "change" },
        ],
        direction: [
          { required: true, message: "请选择朝向", trigger: "change" },
        ],
        address: [
          { required: true, message: "请填写地址", trigger: "blur" },
          { min: 6, max: 100, message: "长度在6-100个字符", trigger: "blur" },
        ],
        rental: [
          { required: true, message: "请填写租金", trigger: "blur" },
          { validator: checkRental, trigger: "blur" },
        ],
        // pic: [
        //     {required: true, message: '请上传图片'}
        // ],
      },
    };
  },
  computed: {
    sum() {
      return isNaN(this.inputFunc * this.jpsum)
        ? 0
        : parseFloat(this.inputFunc) + parseFloat(this.jpsum);
    },
    inputFunc() {
      return isNaN(
        parseFloat(this.addForm.yjjjg) * (parseFloat(this.addForm.jjdz) * 0.1)
      )
        ? 0
        : parseFloat(
            parseFloat(this.addForm.yjjjg) *
              (parseFloat(this.addForm.jjdz) * 0.1)
          ).toFixed(1);
    },
    jpsum() {
      return isNaN(
        parseFloat(this.addForm.yjpjg) * (parseInt(this.addForm.jpdz) * 0.1)
      )
        ? 0
        : parseFloat(
            parseFloat(this.addForm.yjpjg) *
              (parseFloat(this.addForm.jpdz) * 0.1)
          ).toFixed(1);
    },
  },
  methods: {
    rowStyle() {
      return "text-align:center";
    },
    // 查询所在省列表
    queryProvince() {
      this.$http
        .get("/area/-1")
        .then((resp) => {
          if (resp.data.code === 200) {
            this.area.provinceList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 查询所在市列表
    queryCity(provinceId) {
      /* form表单的数据清除 */
      this.addForm.city = "";
      this.addForm.area = "";

      /* 把所在市和所在区的列表数据清除 */
      this.area.cityList = [];
      this.area.areaList = [];

      if (provinceId) {
        this.$http("/area/" + provinceId)
          .then((resp) => {
            if (resp.data.code === 200) {
              this.area.cityList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    queryArea(cityId) {
      /* form表单的数据清除 */
      this.addForm.area = "";
      /* 把和所在区的列表数据清除 */
      this.area.areaList = [];
      if (cityId) {
        this.$http
          .get("/area/" + cityId)
          .then((resp) => {
            if (resp.data.code === 200) {
              this.area.areaList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    queryDict(groupId) {
      if (groupId) {
        this.$http("/code/" + groupId)
          .then((resp) => {
            if (resp.data.code === 200) {
              if (groupId === "PJLX") {
                this.dict.PJLXLIST = resp.data.data;
              } else if (groupId === "XH") {
                this.dict.XHLIST = resp.data.data;
              } else if (groupId === "CL") {
                this.dict.CLLIST = resp.data.data;
              } else if (groupId === "ZSL") {
                this.dict.ZSLLIST = resp.data.data;
              } else if (groupId === "YS") {
                this.dict.YSLIST = resp.data.data;
              } else {
                this.dict.MCLIST = resp.data.data;
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    // 文件上传成功后执行
    onUploadSuccess(resp, file, fileList) {
      // resp: {"code", "msg", "data"}
      if (resp.code === 200) {
        this.addForm.pic = resp.data;
        // 上传成功之后重新验证图片字段
        this.$refs.addFormRef.validateField("pic");
      } else {
        this.$message.error(resp.msg);
      }
    },
    // 超出文件上传个数限制
    onUploadExceed(files, fileList) {
      this.$message.error("只能上传一个文件");
    },
    // 文件上传前调用
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error("图片必须小于1MB");
        return false;
      }

      if (!isJPG && !isPNG) {
        this.$message.error("图片必须jpg/png格式");
        return false;
      }
    },
    onPicRemove(file, fileList) {
      this.addForm.pic = "";
      this.fileList = [];
      // 删除之后重新验证图片字段
      this.$refs.addFormRef.validateField("pic");
    },
    submitAddForm() {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.$http
            .post("/add", this.addForm)
            .then((resp) => {
              if (resp.data.code === 200) {
                this.$message.success("添加成功");
                // resetForm
                this.resetAddForm();
              } else {
                this.$message.error(resp.data.msg);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetAddForm() {
      this.$refs.addFormRef.resetFields();
      this.fileList = [];
    },
  },
  created() {
    this.queryProvince();
    this.queryDict("PJLX");
    this.queryDict("XH");
    this.queryDict("CL");
    this.queryDict("YS");
    this.queryDict("CL");
    this.queryDict("ZSL");
    this.queryDict("MC");
  },
};
</script>